var site = site || {};
var generic = generic || {};
var settings = Drupal.settings || {};

var loyaltyCancelationPopupHeight = typeof settings.loyalty === 'object'
    && typeof settings.loyalty.cancelation_popup === 'object'
    && typeof settings.loyalty.cancelation_popup.height === 'object' ? settings.loyalty.cancelation_popup.height : {};

var loyaltyCancelationPopupWidth = typeof settings.loyalty === 'object'
    && typeof settings.loyalty.cancelation_popup === 'object'
    && typeof settings.loyalty.cancelation_popup.width === 'object' ? settings.loyalty.cancelation_popup.width : {};

(function ($) {
  // Open loyalty cancel content
  var overlay,
    cancelReasonsText,
    $cancelReasonslabel,
    cancelReasonsCheckboxID,
    $element,
    $cancelMembershipButton,
    cancelLoyatySubmit,
    foregroundNode,
    loyaltyCancellationReasons;

  $('.js-loyalty_cancel').click(function () {
    // Get the html content of the template.
    var args, data, darg, params;
    var html = $("script.inline-template[path='loyalty_cancellation_popup']").html();
    var $csrfToken = $.cookie('csrftoken');

    Drupal.behaviors.ELB_welcome15 = {
      // Debug config.
      debug: false,

      // Storage cookie name.
      offerCookie: 'welcome_popup',

      // Of seconds before the overlay appears.
      defaultTimeout: 3000,

      // Template path config.
      templates: {
        form: 'loyalty_cancellation_popup'
      }
    };
    // Overlay config.
    overlay = {
      pc: {
        className: 'loyalty-cancel-overlay',
        width: loyaltyCancelationPopupWidth.pc ? loyaltyCancelationPopupWidth.pc : '435px',
        height: loyaltyCancelationPopupHeight.pc ? loyaltyCancelationPopupHeight.pc : '580px',
        transition: 'none'
      },
      mobile: {
        className: 'loyalty-cancel-overlay',
        width: loyaltyCancelationPopupWidth.mobile ? loyaltyCancelationPopupWidth.mobile : '360px',
        height: loyaltyCancelationPopupHeight.mobile ? loyaltyCancelationPopupHeight.mobile : '505px',
        transition: 'none'
      }
    };

    // Sanitize the incoming data.
    data = Drupal.behaviors.ELB_welcome15.templates.form;

    // Get the html content of the template.

    if (html.length < 1) {
      return $();
    }
    // If there's no data object passed in, then just return a basic jquery element.
    if (data === undefined || !$.isFunction(site.template.render)) {
      return $(html);
    }

    // We have html and data, which means we should use Mustache to render the output.

    args = { html: html };
    darg = site.client.isMobile ? overlay.mobile : overlay.pc;

    $.extend(args, darg);

    $.colorbox(args);

    cancelLoyatySubmit = $('.js-cancel_loyalty_submit');
    foregroundNode = $('#foreground-node');
    loyaltyCancellationReasons = $('input[name="loyalty_cancellation_reasons"]');

    // Do not vertical align the popup in mobile.
    if (site.client.isMobile) {
      foregroundNode.css('top', '0');
    }
    cancelLoyatySubmit.on('click', function (e) {
      e.preventDefault();

      if (cancelReasonsText === '' || cancelReasonsText === undefined) {
        return;
      }
      // Cancel loyalty only if checkbox is enabled.
      params = [
        {
          _SUBMIT: 'loyalty_join',
          LEAVE_CONFIRM: 1,
          LOYALTY_SOURCE: 'Site_Loyalty',
          LOYALTY_ACTIVE_FLAG: 0,
          LOYALTY_CANCELLATION_REASON: cancelReasonsText
        }
      ];

      if ($csrfToken) {
        params[0]._TOKEN = $csrfToken;
      }

      generic.jsonrpc.fetch({
        method: 'rpc.form',
        params: JSON.stringify(params),
        onSuccess: function () {
          window.location.href = '/account/profile_preferences.tmpl';
        },
        onFailure: function (r) {
          if (r.getError().data.messages[0].key === 'not_found.user_attribute.item.') {
            window.location.href = '/account/profile_preferences.tmpl';
          }
        }
      });
    });
    $('.loyalty--popover__buttons--retain').click(function () {
      $.colorbox.close();
    });
    // User has to select only one checkbox and remaining checkoxes should be disabled.
    loyaltyCancellationReasons.on('change', function () {
      $element = $(this);
      $cancelMembershipButton = $('.js-cancel_loyalty_submit');
      loyaltyCancellationReasons.not($element).prop('checked', false);
      cancelReasonsCheckboxID = $element.attr('id');
      $cancelMembershipButton.addClass('button--disabled');
      cancelReasonsText = '';
      if ($element.is(':checked') && cancelReasonsCheckboxID !== 'undefined') {
        $cancelReasonslabel = $("label[for='" + cancelReasonsCheckboxID + "']");
        cancelReasonsText = $cancelReasonslabel.text();
        $cancelMembershipButton.removeClass('button--disabled');
      }
    });
  });
})(jQuery);
